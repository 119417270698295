export class Confianos{
    constructor(
        public name: string,
        public surname: string,
        public telefono: string,
        public email: string,
        public inmueble: string,
        public operacion: string,
        public precio: number,
        public direccion: string,
        public ciudad: string,
        public mensaje: string,
    
    ) {}
}